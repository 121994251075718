body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #142735;
  --amplify-primary-tint: #253948;
  --amplify-primary-shade: #12202b;
  --amplify-background-color: #eeeeee;
  --amplify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.title{
  color: #142735;
  font-weight: 300;
  padding: 20px 0 5px 0;
  display: block;
  font-size: 2rem;
  text-align: start;
}

.common-button{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.MuiTableCell-root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
}
.alignmentCenter{
  align-items: center;
  color: #2d4352;
  
  padding:5px;
  border: var(--amplify-primary-color);
  font-family: sans-serif;
  
}

.panels > div {
 /* Add a black border around each panel for debugging*/
  border: 2px solid rgb(88, 69, 138);
}

.panels {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 225px auto auto;
  grid-template-rows: auto auto;
}
.panel-info {
  grid-row: span 2;
  grid-column: span 1;
}
.panel-positions {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-con {
  grid-column-gap: 50px;
  display: grid;
  grid-gap: 50px 100px;
  grid-template-columns: auto auto auto;

 
}
.griditem {
  
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; 
  padding: 5px;
  text-align: center;
  border-style: outset;
  background-color: white;
  color: black;
  border: 4px solid #e7e7e7;
}
.griditem:hover {background-color:white;color:white; font-size: 110%;font-weight: bold;}

.tabs{
  border-bottom: 1px solid #dddddd;
}

.no-verticle-wrappers {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d; }

h1, .h1 {
  font-size: 1.625rem; }

h2, .h2 {
  font-size: 1.25rem; }
 
  .text-uppercase {
    text-transform: uppercase !important; }

    .text-muted {
      color: #8898aa !important; }
    .icon {
  width: 3rem;
  height: 3rem; }
  .icon i, .icon svg {
    font-size: 2.25rem; }
  .icon + .icon-text {
    padding-left: 1rem;
    width: calc(100% - 3rem - 1); }

.icon-xl {
  width: 5rem;
  height: 5rem; }
  .icon-xl i, .icon-xl svg {
    font-size: 4.25rem; }
  .icon-xl + .icon-text {
    width: calc(100% ); }

.icon-lg {
  width: 4rem;
  height: 4rem; }
  .icon-lg i, .icon-lg svg {
    font-size: 3.25rem; }
  .icon-lg + .icon-text {
    width: calc(100% ); }

.icon-sm {
  width: 2rem;
  height: 2rem; }
  .icon-sm i, .icon-sm svg {
    font-size: 1.25rem; }
  .icon-sm + .icon-text {
    width: calc(100% ); }

.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }
  .icon-shape i, .icon-shape svg {
    font-size: 1.25rem; }
  .icon-shape.icon-lg i, .icon-shape.icon-lg svg {
    font-size: 1.625rem; }
  .icon-shape.icon-sm i, .icon-shape.icon-sm svg {
    font-size: .875rem; }
  .icon-shape svg {
    width: 30px;
    height: 30px; }

.icon-shape-primary {
  color: #2643e9;
  background-color: rgba(138, 152, 235, 0.5); }

.icon-shape-secondary {
  color: #cfe3f1;
  background-color: rgba(255, 255, 255, 0.5); }

.icon-shape-success {
  color: #1aae6f;
  background-color: rgba(84, 218, 161, 0.5); }

.icon-shape-info {
  color: #03acca;
  background-color: rgba(65, 215, 242, 0.5); }

.icon-shape-warning {
  color: #ff3709;
  background-color: rgba(252, 140, 114, 0.5); }

.icon-shape-danger {
  color: #f80031;
  background-color: rgba(247, 103, 131, 0.5); }

.icon-shape-light {
  color: #879cb0;
  background-color: rgba(201, 207, 212, 0.5); }

.icon-shape-dark {
  color: #090c0e;
  background-color: rgba(56, 63, 69, 0.5); }

.icon-shape-default {
  color: #091428;
  background-color: rgba(35, 65, 116, 0.5); }

.icon-shape-white {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5); }

.icon-shape-neutral {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5); }

.icon-shape-darker {
  color: black;
  background-color: rgba(26, 26, 26, 0.5); }

  .text-success {
    color: #2dce89 !important; }
    .arrow {
      border: solid rgb(37, 187, 17);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 6px;
    }
    
    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    
    .up {
      transform: rotate(-500deg);
      -webkit-transform: rotate(-135deg);
    }
   
@media (max-width: 750px) {
  .panels {
    grid-template-columns: 225px auto;
    grid-template-rows: auto auto auto;
  }
  .panel-positions {
    grid-column: span 2;
  }
}

@media (max-width: 500px) {
  .panels {
    grid-template-columns: auto;
  }
  .panel-positions {
    grid-column: span 1;
  }
  .griditem{
    grid-template-columns: auto;
  }
}