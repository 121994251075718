
.form-control{
    display: flex !important;
    width: 100%;
    margin-bottom: 20px !important;
}
.form-control .MuiOutlinedInput-input {
    padding: 12.5px 12px !important;
    font-size: small !important;
    text-align: justify;
}
.form-control.no-padding .MuiOutlinedInput-input {
    padding: 0 !important;
    font-size: small !important;
    text-align: justify;
} 
.filter-button{
    /* background-color: #142735 !important;
    color: #fff !important; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}