
.logout{
    display: inline-block;
    background: transparent;
    padding: 10px 10px;
    min-width: 100px;
    margin: 0 0px 0px 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: white; 
    font-weight: 600; 
    letter-spacing: 1px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
    opacity: 0.7;
}
.logout:hover{
    opacity: 1;
}