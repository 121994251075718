.app-bar{
    background-color: #142735 !important;
}
.tool-bar{
    display: flex; 
    justify-content: space-between
}
.navbar {
    display: inline-block;
}
.navbar ul {
    list-style: none; 
    margin: 0; 
    padding: 0; 
    display: block; 
}
.navbar li{
    display: inline-block;
}
.navbar a {
    color: white; 
    font-weight: 300; 
    letter-spacing: 2px; 
    text-decoration: none;
    padding: 20px 10px; 
    display: inline-block;
    text-align: center; 
}
.navbar a:hover,
.navbar .is-active{
    font-weight: 600;
}
