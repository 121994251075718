table.admin-table{
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}
table.admin-table thead > tr > td {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
    line-height: 1.5rem;
}
table.admin-table tr > td {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 400;
    line-height: 1;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}
.more-button{
    width: 60%;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    padding: 5px 15px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
.icon-button {
    padding: 6px !important;
}
.close-button {
    position: absolute !important;
    top: 10px;
    right: 10px;
}
.overlay-button{
    margin: 0 18px 6px 0 !important;
    width: 150px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    padding: 5px 15px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
.search-input {
    margin-left: 10px !important;
}

.selective-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.selective-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}