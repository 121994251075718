.item-add-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.item-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.item-delete-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    flex-wrap: wrap;
}